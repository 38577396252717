import "core-js/modules/es7.object.values.js";
import "core-js/modules/es6.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import * as moment from 'moment';
var WINNERS_TYPES = {
  ALL: 'ALL',
  RED: 'RED',
  SILVER: 'SILVER',
  GOLD: 'GOLD',
  DIAMOND: 'DIAMOND'
};
export default {
  name: 'app-jackpot-table',
  props: {
    currency: String
  },
  data: function data() {
    return {
      currentTab: WINNERS_TYPES.ALL
    };
  },
  computed: {
    winners: function winners() {
      return this.$store.getters['jackpots/winners'];
    },
    tabs: function tabs() {
      return Object.values(WINNERS_TYPES);
    },
    currentTabWinners: function currentTabWinners() {
      var _this = this;

      return this.winners.filter(function (w) {
        return _this.currentTab === WINNERS_TYPES.ALL || w.title === _this.currentTab;
      });
    },
    thumbStyle: function thumbStyle() {
      return {
        right: this.right,
        borderRadius: '2px',
        backgroundColor: '#fff',
        width: '2px',
        opacity: 1,
        zIndex: 4
      };
    },
    barStyle: function barStyle() {
      return {
        right: this.right,
        borderRadius: '4px',
        backgroundColor: '#fff',
        width: '1px',
        opacity: 0.1,
        zIndex: 4
      };
    }
  },
  methods: {
    changeTab: function changeTab(tab) {
      this.currentTab = tab;
    },
    preparedDate: function preparedDate(date) {
      moment.locale(this.$store.getters['lang/lang']);
      return moment(date, 'YYYY-MM-DD hh:mm').calendar({
        lastDay: "[".concat(this.$t('date.yesterday'), ", ] LT"),
        sameDay: "[".concat(this.$t('date.today'), ", ] LT"),
        nextDay: "[".concat(this.$t('date.tomorrow'), " ,] LT"),
        lastWeek: 'DD MMM YYYY, LT',
        nextWeek: 'DD MMM YYYY, LT',
        sameElse: 'DD MMM YYYY, LT'
      });
    }
  }
};